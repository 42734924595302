import * as styles from './Table.module.scss'

import React from 'react'
import classNames from 'classnames'

import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'

interface Props {
  children: React.ReactNode
  textWidth?: boolean
}

const Table: React.FC<Props> = (props) => {
  const { children, textWidth } = props

  return (
    <>
      {textWidth ? (
        <table className={classNames(styles.table, 'is-textWidth')}>
          {children}
        </table>
      ) : (
        <HorizontalScrollingContainer>
          <table className={styles.table}>{children}</table>
        </HorizontalScrollingContainer>
      )}
    </>
  )
}

export default Table
