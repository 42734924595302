import * as styles from './TableRow.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

const TableRow: React.FC<Props> = (props) => {
  const { children } = props

  return <tr className={styles.row}>{children}</tr>
}

export default TableRow
