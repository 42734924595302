import React from 'react'

import TableCell from './TableCell'

interface Props {
  children: React.ReactNode
  align?: 'left' | 'center' | 'right' | 'justify'
}

const TableDataCell: React.FC<Props> = (props) => {
  const { children, align } = props

  return (
    <td align={align}>
      <TableCell>{children}</TableCell>
    </td>
  )
}

export default TableDataCell
