import * as styles from './TableHeaderCell.module.scss'

import React from 'react'

import TableCell from './TableCell'

interface Props {
  children: React.ReactNode
  align?: 'left' | 'center' | 'right' | 'justify'
}

const TableHeaderCell: React.FC<Props> = (props) => {
  const { children, align } = props

  return (
    <th align={align} className={styles.cell}>
      <TableCell>{children}</TableCell>
    </th>
  )
}

export default TableHeaderCell
